
export default {
    emits: ['update:visible'],
    props: {
        // 弹窗是否打开
        visible: Boolean,
        // 当前用户数据
        data: Object
    },
    data() {
        return {
            groupData: [],
            // 数据加载状态
            dataLoading: false,
            // 提交状态
            loading: false,
            // 展开的keys
            expandKeys: [],
            // 选中的keys
            checkedKeys: [],
        };
    },
    watch: {
        visible() {
            if (this.visible) {
                this.query();
            }
        }
    },
    methods: {
        query() {
            this.groupData = [];
            this.expandKeys = [];
            this.checkedKeys = [];

            if (!this.data) {
                return;
            }

            this.dataLoading = true;
            this.$http.get(`/admin/charge_group/user_list/${this.data.id}`).then(res => {
                this.dataLoading = false;

                if (res.data.code === 0) {
                    let eks = [],
                        cks = [];
                    res.data.data.forEach(d => {
                        d.key = d.id;
                        d.title = `[${d.begin_time} ~ ${d.end_time}] ${d.name} (${d.discount * 100}%)`;
                        eks.push(d.key);
                    });
                    this.groupData = this.$util.toTreeData(res.data.data, 'id');
                    this.$util.eachTreeData(this.groupData, (d) => d.checked && cks.push(d.key));
                    this.expandKeys = eks;
                    this.checkedKeys = cks;
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                this.dataLoading = false;
                this.$message.error(e.message);
            });
        },
        save() {
            this.loading = true;
            let ids = [];
            this.$util.eachTreeData(this.groupData, (d) => {
                if (this.checkedKeys.some(c => c === d.key)) {
                    ids.push(d.key);
                }
            });
            this.$http.post(`/admin/charge_group/update_user_group/${this.data.id}`, { ids: ids }).then(res => {
                this.loading = false;
                if (res.data.code === 0) {
                    this.$message.success(res.data.msg);
                    this.updateVisible(false);
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                this.loading = false;
                this.$message.error(e.message);
            });
        },
        /* 更新visible */
        updateVisible(value) {
            this.$emit('update:visible', value);
        }
    }
}
